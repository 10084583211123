@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your global stylesheet, e.g., styles.css */
@font-face {
  font-family: myFirstFont;
  src: url("../public//fonts/VCR_OSD_MONO_1.001.ttf") format("truetype");
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: myFirstFont;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
